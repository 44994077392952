import React from 'react'
import Layout from '../Layout/Layout'
import { ConfigProvider } from 'antd'
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom'
import Landing from 'components/Landing/Landing'
import Upload from 'components/Settings/Upload'
import Login from 'components/Login/Login'
import { authService } from 'services/auth'
import Assistant from 'components/Assistant/Assistant'
import { Worker } from '@react-pdf-viewer/core'
import 'react-perfect-scrollbar/dist/css/styles.css'
import Users from '../Settings/Users'
import Groups from '../Settings/Groups'
import { defaultProfile } from 'theme/profiles'

async function loginLoader() {
  if (!authService.isAuthenticated()) {
    return redirect('/login')
  }
  return authService.user
}

async function adminLoader() {
  if (!authService.isAuthenticated() || authService.user?.role !== 'admin') {
    return redirect('/login')
  }
  return authService.user
}

const App = () => {
  const router = createBrowserRouter([
    {
      id: 'root',
      path: '/',
      element: <Layout />,
      loader: loginLoader,
      children: [
        {
          index: true,
          path: '',
          element: <Assistant />
        },
        {
          path: 'settings',

          children: [
            {
              path: 'uploads',
              Component: Upload
            },
            {
              path: 'users',
              Component: Users,
              loader: adminLoader
            },
            {
              path: 'groups',
              Component: Groups,
              loader: adminLoader
            }
          ]
        }
      ]
    },
    {
      path: '/landing',
      loader: loginLoader,
      Component: Landing
    },
    {
      path: '/login',
      element: <Login />
    },

    {
      path: '/logout',
      loader: async (logout) => {
        localStorage.removeItem('AccessToken')
        localStorage.removeItem('isAuthenticated')
        localStorage.removeItem('currentUser')
        return true
      },
      element: <Login />
    }
  ])

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: defaultProfile.actionPrimaryColor
        }
      }}
    >
      <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
        <RouterProvider
          router={router}
          fallbackElement={<p>Initial Load...</p>}
        />
      </Worker>
    </ConfigProvider>
  )
}
export default App
