import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { api, rtkQueryErrorLogger } from './apiSlice'
import { modelAPI } from './modelSlice'

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [modelAPI.reducerPath]: modelAPI.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      api.middleware,
      modelAPI.middleware,
      rtkQueryErrorLogger
    )
})

setupListeners(store.dispatch)
