import { Select } from 'antd'
import { IModel, IUser } from 'models'
import { FC, useContext, useEffect, useState } from 'react'
import { useUpdateUserMutation } from 'redux/apiSlice'
import { ProductOutlined } from '@ant-design/icons'
import { CurrentUserContext } from 'redux/contexts'

interface IProps {
  setSelectedModel: (model: string) => void
  modelList: IModel[]
}

const ModelSelector: FC<IProps> = (props) => {
  const { setSelectedModel, modelList } = props
  const currentUser = useContext(CurrentUserContext)
  const default_model =
    currentUser != undefined ? currentUser.default_model : ''
  const [defaultValue] = useState<string>(default_model)
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation()

  const onChange = async (value: string) => {
    setSelectedModel(value)

    await updateUser({ ...currentUser, default_model: value })
    localStorage.setItem(
      'currentUser',
      JSON.stringify({ ...currentUser, default_model: value } as IUser)
    )
  }

  useEffect(() => {
    if (currentUser) {
      setSelectedModel(currentUser.default_model)
    }
  }, [currentUser])

  return (
    <Select
      showSearch
      defaultValue={defaultValue}
      placeholder='Select a model'
      optionFilterProp='value'
      onChange={onChange}
      style={{ width: 250 }}
      options={modelList?.map((model) => ({
        label: (
          <>
            <ProductOutlined /> <strong>{model.name}</strong>{' '}
            {model.details.parameter_size}
          </>
        ),
        value: model.name
      }))}
    />
  )
}

export default ModelSelector
