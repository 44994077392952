export const environment = {
  DEBUG: process.env.REACT_APP_DEBUG || 'False'
}

export const config = {
  BASE_URL:
    environment.DEBUG === 'True'
      ? 'http://127.0.0.1:8001/api/v1/'
      : process.env.REACT_APP_API_URL,
  LLM_URL: process.env.REACT_APP_LLM_URL,
  OSS_ENDPOINT: process.env.REACT_APP_OSS_ENDPOINT,
  OSS_BUCKET_NAME: process.env.REACT_APP_OSS_BUCKET_NAME,
  OSS_MAIN_DIR: process.env.REACT_APP_OSS_MAIN_DIR,
  TRANSLATION_URL: process.env.REACT_APP_JAIS_URL,
  MAX_FILE_SIZE: Number(process.env.MAX_FILE_SIZE) || 40,
  MAX_FILE_COUNT: Number(process.env.MAX_FILE_COUNT) || 5,
  DEFAULT_PROFILE: process.env.REACT_APP_DEFAULT_PROFILE || 'stc'
}
