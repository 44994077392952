import { Context, createContext } from 'react'
import { ClientProfile, Group, IUser } from 'models'
import { defaultProfile } from 'theme/profiles'

export const ClientContext: Context<ClientProfile> =
  createContext(defaultProfile)

export const defaultGroup: Group = {
  value: '',
  label: 'Select Group'
}

export const GroupContext: Context<Group> = createContext(defaultGroup)

export const AccessTokenContext: Context<string> = createContext('')
export const CurrentUserContext: Context<IUser> = createContext({} as IUser)
