import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from 'services/config'
import { IModel } from '../models'

export const modelAPI = createApi({
  reducerPath: 'modelAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
    prepareHeaders: (headers, { getState, endpoint }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const accessToken = localStorage.getItem('AccessToken')
      if (accessToken && endpoint !== 'login') {
        headers.set('Authorization', `Bearer ${accessToken}`)
      }
      return headers
    },

    credentials: 'same-origin'
  }),
  tagTypes: ['models'],
  endpoints: (builder) => ({
    listModels: builder.query<IModel[], string>({
      query: () => ({
        url: `/models`
      }),
      providesTags: [{ type: 'models', id: 'listModels' }]
    })
  })
})
export const { useListModelsQuery } = modelAPI
