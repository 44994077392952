import { Message, SourceDocument } from 'models/message'
import { v4 as uuidv4 } from 'uuid'
import { defaultProfile } from 'theme/profiles'

export enum MessageOrigin {
  SENDER = 'SENDER',
  RECEIVER = 'RECEIVER'
}

export const generateMessage = (
  message: string,
  type: MessageOrigin = MessageOrigin.SENDER,
  status: 'waiting' | 'sent' | 'received' | 'read' = 'received',
  firstInSequence: boolean = false,
  createdAt: Date = new Date(),
  id: string = String(Math.random()),
  sourceDocuments: SourceDocument[] | string[] = [],
  likes: number = 0,
  dislikes: number = 0,
  agentType: string = 'rag'
): Message => {
  return {
    status: status,
    agentType: agentType,
    type: {
      type: 'text',
      id: id,
      position: type === MessageOrigin.SENDER ? 'right' : 'left',
      text: message,
      title: type === MessageOrigin.SENDER ? 'You' : defaultProfile.appName,
      focus: false,
      date: createdAt,
      avatar:
        type === MessageOrigin.SENDER
          ? defaultProfile.thumbnailLogo
          : 'https://freesvg.org/img/1538298822.png',
      titleColor: 'black',
      forwarded: false,
      replyButton: false,
      removeButton: false,
      status: status,
      statusTitle: 'Desktop',
      notch: true,
      copiableDate: true,
      retracted: false,
      className: type === MessageOrigin.SENDER ? 'sender' : 'target',
      reply: undefined
    },
    sourcesDocuments: sourceDocuments,
    chat_id: firstInSequence ? uuidv4() : 'placeholder',
    likes: likes,
    dislikes: dislikes
  }
}

export const capitalize = (word: string) => {
  if (!word) return word
  return word[0].toUpperCase() + word.substr(1).toLowerCase()
}


export const isUUID = (input: string) => {
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
    return regexExp.test(input)
  }