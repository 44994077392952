import { useContext } from 'react'
import { ClientContext } from '../redux/contexts'

interface IColors {
  primary: string
  secondary: string
  lightblue: string
}

interface ILogos {
  main: string
  thumbnail: string
}

interface ISizes {
  main: string
  thumbnail: string
}

const useTheme = () => {
  const profile = useContext(ClientContext)
  const COLORS: IColors = {
    primary: profile.primaryColor,
    lightblue: '#e6f4ff',
    secondary: profile.secondaryColor
  }

  const LOGOS: ILogos = {
    main: profile.logo,
    thumbnail: profile.thumbnailLogo
  }

  const SIZES: ISizes = {
    main: profile.mainLogoSize,
    thumbnail: profile.thumbnailLogoSize
  }

  return { COLORS, LOGOS, SIZES }
}

export default useTheme
