import styled from 'styled-components'
import { Card } from 'antd'
import { defaultProfile } from '../../theme/profiles'

export const Wrapper = styled.div`
  background-color: ${defaultProfile.primaryColor};
  height: 100%;
  position: absolute;
  width: 100%;
`

export const FormWrapper = styled(Card)`
  max-width: 650px;
  margin: 100px auto auto;
  padding: 25px 70px;
  border-radius: 10px;
  background-color: #ffffff;
`

export const FromTitle = styled.h1`
  margin-bottom: 50px;
  font-size: 3em !important;
  font-weight: 700;
  text-align: center;
  color: ${defaultProfile.primaryColor};
`
